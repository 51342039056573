import { configureStore } from '@reduxjs/toolkit';
import cartReducer from '@app/stores/redux/cart/cartSlice';
import modalReducer from '@app/stores/redux/modalSlice';
import themeReducer from '@app/stores/redux/themeSlice';
import counterReducer from '@app/stores/redux/counterSlice';
import postReducer from '@app/stores/redux/postSlice';
import userReducer from '@app/stores/redux/usersSlice';

export const storeRedux = configureStore({
	reducer: {
		cart: cartReducer,
		modal: modalReducer,
		theme: themeReducer,
		counter: counterReducer,
		posts: postReducer,
		users: userReducer,
	},
});
