import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isOpen: false,
	palette: 'dark',
};

const themeSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		openMenu: (state, action) => {
			state.isOpen = true;
		},
		closeMenu: (state, action) => {
			state.isOpen = false;
		},
		setThemeApp: (state, { payload }) => {
			state.palette = payload;
		},
	},
});

export const { openMenu, closeMenu, setThemeApp } = themeSlice.actions;
export default themeSlice.reducer;
