import { createSlice, nanoid, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { current } from 'immer';
import dayjs from 'dayjs';
import axios from 'axios';

const POSTS_URL = 'https://jsonplaceholder.typicode.com/posts';

// Optimization
// https://www.youtube.com/watch?v=NqzdVN2tyvQ&t=7856s&ab_channel=DaveGray

const initialState = {
	posts: [],
	status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
	error: null,
	count: 0,
};

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
	const response = await axios.get(POSTS_URL);
	return response.data;
});

export const addNewPost = createAsyncThunk('posts/addNewPost', async initialPost => {
	const response = await axios.post(POSTS_URL, initialPost);
	return response.data;
});

export const updatePost = createAsyncThunk('posts/updatePost', async initialPost => {
	const { id } = initialPost;
	try {
		const response = await axios.put(`${POSTS_URL}/${id}`, initialPost);
		return response.data;
	} catch (err) {
		//return err.message;
		return initialPost; // only for testing Redux!
	}
});

export const deletePost = createAsyncThunk('posts/deletePost', async initialPost => {
	const { id } = initialPost;
	try {
		const response = await axios.delete(`${POSTS_URL}/${id}`);
		if (response?.status === 200) return initialPost;
		return `${response?.status}: ${response?.statusText}`;
	} catch (err) {
		return err.message;
	}
});

const postSlice = createSlice({
	name: 'post',
	initialState,
	reducers: {
		postAdded: {
			reducer(state, action) {
				state.posts.push(action.payload);
			},
			prepare(title, content, userId) {
				return {
					payload: {
						id: nanoid(),
						title,
						content,
						date: new Date().toISOString(),
						userId,
						reactions: {
							thumbsUp: 0,
							wow: 0,
							heart: 0,
							rocket: 0,
							coffee: 0,
						},
					},
				};
			},
		},
		reactionAdded(state, action) {
			console.log('%c Boom 01', 'color:red', action);
			console.log('%c Boom 02', 'color:red', current(state));

			const { postId, reaction } = action.payload;
			const existingPost = state.posts.find(post => post.id === postId);

			if (existingPost) {
				existingPost.reactions[reaction]++;
			}
		},
		increaseCount(state, action) {
			state.count = state.count + 1;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchPosts.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchPosts.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Adding date and reactions
				let min = 1;
				const loadedPosts = action.payload.map(post => {
					post.date = dayjs(new Date(), { minutes: min++ }).toISOString();
					post.reactions = {
						thumbsUp: 0,
						wow: 0,
						heart: 0,
						rocket: 0,
						coffee: 0,
					};
					return post;
				});

				// Add any fetched posts to the array
				state.posts = state.posts.concat(loadedPosts);
			})
			.addCase(fetchPosts.rejected, (state, action) => {
				state.status = 'failed';
				state.error = 'action.error.message';
			})
			.addCase(addNewPost.fulfilled, (state, action) => {
				// Fix for API post IDs:
				// Creating sortedPosts & assigning the id
				// would be not be needed if the fake API
				// returned accurate new post IDs
				const sortedPosts = state.posts.sort((a, b) => {
					if (a.id > b.id) return 1;
					if (a.id < b.id) return -1;
					return 0;
				});
				action.payload.id = sortedPosts[sortedPosts.length - 1].id + 1;
				// End fix for fake API post IDs
				action.payload.userId = Number(action.payload.userId);
				action.payload.date = new Date().toISOString();
				action.payload.reactions = {
					thumbsUp: 0,
					wow: 0,
					heart: 0,
					rocket: 0,
					coffee: 0,
				};
				console.log(action.payload);
				state.posts.push(action.payload);
			})
			.addCase(updatePost.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(updatePost.fulfilled, (state, action) => {
				state.status = 'succeeded';
				if (!action.payload?.id) {
					console.log('Update could not complete');
					console.log(action.payload);
					return;
				}
				const { id } = action.payload;
				action.payload.date = new Date().toISOString();
				const posts = state.posts.filter(post => post.id !== id);
				state.posts = [...posts, action.payload];
			})
			.addCase(deletePost.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(deletePost.fulfilled, (state, { payload }) => {
				state.status = 'succeeded';
				if (!payload?.id) {
					console.log('Delete could not complete');
					console.log(payload);
					return;
				}
				const { id } = payload;
				const posts = state.posts.filter(post => post.id !== id);
				state.posts = posts;
			});
	},
});

export const selectAllPosts = state => state.posts.posts;
export const getPostsStatus = state => state.posts.status;
export const getPostsError = state => state.posts.error;
export const getCount = state => state.posts.count;

export const selectPostsByUser = createSelector([selectAllPosts, (state, userId) => userId], (posts, userId) =>
	posts.filter(post => post.userId === userId)
);

export const selectPostById = (state, postId) => state.posts.posts.find(post => post.id === postId);

export const { postAdded, reactionAdded, increaseCount } = postSlice.actions;
export default postSlice.reducer;
